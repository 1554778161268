import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../../components";
import axios from "axios";
import MapComponent from "../../components/MapComponent";
import { useNavigate } from "react-router-dom";
import LeafletMap from "../../components/LeaftletMap";

const CreatePlaces = () => {
  const navigate = useNavigate();
  const [place, setPlace] = useState("");
  console.log(place);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  

  const [areaData, setAreaData] = useState([]);
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [placeData, setPlaceData] = useState([]);
  const [modalAction, setModalAction] = useState("update");
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  const [updatedPlace, setUpdatedPlace] = useState();
  const [areaId, setAreaId] = useState(null);
  console.log(placeData);

  const handleAreaChange = (e) => {
    setSelectedAreaId(e.target.value);
  };

  const getArea = useCallback(() => {
    const url = `${baseUrl}area/get`;

    return axios
      .get(url)
      .then((response) => {
        console.log(response.data);
        setAreaData(response.data.data);
        return response.data.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }, [baseUrl]);

  const getPlaces = useCallback(async () => {
    try {
      const url = `${baseUrl}place/get-places`;
      console.log(url);
      const response = await axios.get(url);
      setPlaceData(response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }, [baseUrl]);

  useEffect(() => {
    getArea()
      .then((data) => {
        console.log("Data fetched:", data);
      })
      .catch((error) => {
        console.error("Error in useEffect:", error);
      });
    getPlaces()
      .then((data) => {
        console.log("Places Data Fetched", data);
      })
      .catch((error) => {
        console.log("Error In useCallback:", error);
      });
  }, [getArea, getPlaces]);

  console.log(placeData);

  const updateClick = ({ areaId, placeId }) => {
    console.log(areaId, placeId);
    setSelectedPlaceId(placeId);
    setAreaId(areaId);
    setModalAction("update");
  };

  const updatePlace = async () => {
    try {
      if (!selectedPlaceId) {
        alert("invalid Input for updateSubcategory");
        return;
      }
      const url = `${baseUrl}place/update/${selectedPlaceId}`;
      const data = {
        place_address: updatedPlace,
        area_id: areaId,
      };
      const response = await axios.patch(url, data);
      document.getElementById("defaultModal").click();
      setUpdatedPlace("");
      getPlaces();
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  //delete place

  const deleteClick = (id) => {
    console.log(id);
    setSelectedPlaceId(id);
    setModalAction("delete");
  };

  const deletePlace = async () => {
    try {
      if (!selectedPlaceId) {
        console.error("Invalid Id not found");
        return;
      }
      const url = `${baseUrl}place/delete/${selectedPlaceId}`;
      const response = await axios.delete(url);
      console.log(response.data);
      document.getElementById("defaultModal").click();
      getPlaces();
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalAction = async () => {
    if (modalAction === "update") {
      await updatePlace();
    } else if (modalAction === "delete") {
      await deletePlace();
    }
  };

  return (
    <>
      <Layout>
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 style={{ color: "#3498db", marginBottom: "20px" }}>
                  Places
                </h2>
              </div>
              <div className="body demo-card">
                <div
                  className="row clearfix"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="col-lg-4 col-md-12">
                    <div className="card" style={{ border: "none" }}>
                      <label style={{ fontSize: "18px", marginBottom: "10px" }}>
                        Area Selection
                      </label>
                      <div className="c_multiselect">
                        <select
                          id="single-selection"
                          name="single_selection"
                          className="multiselect multiselect-custom"
                          style={{
                            width: "100%",
                            padding: "10px",
                            fontSize: "16px",
                            borderRadius: "5px",
                            backgroundColor: "#fff",
                            color: "#555",
                            border: "1px solid #ddd",
                            cursor: "pointer",
                            outline: "none",
                          }}
                          onChange={handleAreaChange}
                          value={selectedAreaId}
                        >
                          <option value="0">Select a Area</option>
                          {areaData &&
                            areaData?.map((area) => (
                              <option key={area.area_id} value={area.area_id}>
                                {area.area_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="card">
                      <div className="header">
                        <h2>Create Place</h2>
                      </div>
                      <div className="body">
                        <form id="basic-form" noValidate>
                          <div className="form-group">
                            <label>Write the Place</label>
                            <input
                              type="text"
                              className="form-control"
                              value={place}
                              onChange={(e) => setPlace(e.target.value)}
                              required
                            />
                          </div>
                          {/* <button type="button" className="btn btn-primary">
                            Submit
                          </button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <div className="header">
                      <h2>
                        First Choose a position Then name that Place and Create
                      </h2>
                    </div>
                    <MapComponent placeName={place} areaId={selectedAreaId} />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="header">
            <h2>Places List</h2>
          </div>
          <div className="body">
            <div className="table-responsive">
              <table className="table table-hover mb-0 c_list">
                <thead>
                  <tr>
                    <th>Area Name</th>
                    <th>Place Name</th>
                    <th>Update</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {placeData.map((PlaceItem) => (
                    <tr key={PlaceItem.place_id}>
                      <td>
                        <p className="c_name">{PlaceItem?.area?.area_name}</p>
                      </td>
                      <td>
                        <p className="c_name">{PlaceItem.place_address}</p>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-success"
                          data-toggle="modal"
                          data-target="#defaultModal"
                          onClick={() =>
                            updateClick({
                              placeId: PlaceItem.place_id,
                              areaId: PlaceItem?.area?.area_id,
                            })
                          }
                        >
                          <i className="fa fa-check-circle"></i>{" "}
                          <span>Edit</span>
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-toggle="modal"
                          data-target="#defaultModal"
                          onClick={() => deleteClick(PlaceItem.place_id)}
                        >
                          <i className="fa fa-trash-o"></i> <span>Delete</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>

      {/* Modal */}
      <div className="modal fade" id="defaultModal" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                {modalAction === "update" ? "Update Place" : "Delete Place"}
              </h4>
            </div>
            <div className="modal-body">
              {modalAction === "update" ? (
                <>
                  <label>Place Name Write Here</label>
                  <input
                    type="text"
                    className="form-control"
                    value={updatedPlace}
                    onChange={(e) => setUpdatedPlace(e.target.value)}
                    required
                  />
                </>
              ) : (
                <p>Are you sure you want to delete this Place?</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={
                  modalAction === "update"
                    ? "btn btn-success"
                    : "btn btn-danger"
                }
                data-dismiss="modal"
                onClick={handleModalAction}
                required
              >
                {modalAction === "update" ? "SAVE CHANGES" : "DELETE"}
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePlaces;
