import React, { useRef, useEffect, useState } from "react";
import useLocalStorage from "../utils/useLocalStorage";

const Test = () => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const { storedValue, setValue } = useLocalStorage("iamge", 0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const handleMouseDown = (e) => {
      setIsDrawing(true);
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setLastX(x);
      setLastY(y);
      ctx.beginPath();
      ctx.moveTo(x, y);
    };

    const handleMouseMove = (e) => {
      if (!isDrawing) return;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      ctx.lineTo(x, y);
      ctx.stroke();
      setLastX(x);
      setLastY(y);
    };

    const handleMouseUp = () => {
      setIsDrawing(false);
      const base64Image = canvas.toDataURL();
      setValue(base64Image);
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDrawing, setValue]);

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setValue(null)
  };

  return (
    <>
      <h2>Component</h2>
      <button className="btn btn-success" onClick={clearCanvas}>Clear Canvas</button>
      <figure style={{
        backgroundColor : "purple"
      }}>
      <canvas ref={canvasRef} height={800} width={900}/>
      </figure>
    </>
  );
};

export default Test;
