import { useState } from 'react'

const useLocalStorage = (key,initValue) => {

  const [storedValue,setStoredValue] = useState(() => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : initValue;
  });

  const setValue = (value) => {
    setStoredValue(value);
    localStorage.setItem(key,JSON.stringify(value))
  }
  return {storedValue,setValue}
}

export default useLocalStorage