import React from "react";

const Loader = () => {
  return (
    <>
      <div className="page-loader-wrapper">
        <div className="loader">
          <h5 style={{
            color: "white"
          }}>Loading...Please wait...</h5>
        </div>
      </div>
    </>
  );
};

export { Loader }
