import React, { useCallback, useState, useEffect } from "react";
import { Layout } from "../components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components";

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [userDetailsData, setUserDetailsData] = useState([]);
  const [isLoading, setIsLoding] = useState(false);
  const [favVendorData, setfavVendorData] = useState([]);

  const getUserDetails = useCallback(async () => {
    setIsLoding(true);
    const url = `${baseUrl}users/list/${id}`;
    const token = localStorage.getItem("token");
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setUserDetailsData(response.data.data);
    setIsLoding(false);
    return response.data;
  }, [id, baseUrl]);

  const getFavVendors = useCallback(async () => {
    try {
      const url = `${baseUrl}favorites/${id}`;
      const response = await axios.get(url);
      console.log(response.data.favoriteVendors);
      setfavVendorData(response.data.favoriteVendors);
      return response;
    } catch (error) {
      console.log(error);
    }
  }, [baseUrl, id]);

  useEffect(() => {
    getUserDetails();
    getFavVendors();
  }, [getUserDetails, getFavVendors]);
  console.log(userDetailsData);
  console.log(favVendorData);
  const imageUrl = "https://brandapi.kreativemachinez.in/uploads/";
  return (
    <>
      <Layout>
        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="row clearfix">
            <div
              className="col-lg-6 col-md-12"
              style={{
                display: "contents",
              }}
            >
              <div className="card">
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-12">
                      <div className="receipt-left">
                        <img
                          className="img-fluid"
                          src={`${imageUrl}${userDetailsData?.profile_photo}`}
                          style={{ width: 71, height: 71, borderRadius: 43 }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 text-right">
                      <h5>
                        {userDetailsData.user_type_id === 1
                          ? "Admin"
                          : userDetailsData.user_type_id === 2
                          ? "User"
                          : null}
                      </h5>
                      <p className="mb-0">
                        +91 {userDetailsData.contact_number}{" "}
                        <i className="fa fa-phone" />
                      </p>
                      <p className="mb-0">
                        {userDetailsData?.email_id}{" "}
                        <i className="fa fa-envelope-o" />
                      </p>
                      <p>
                        Indian <i className="fa fa-location-arrow" />
                      </p>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-8 col-md-12">
                      <h5>
                        {userDetailsData?.first_name}
                        {userDetailsData?.last_name}
                        <small>
                          &nbsp; | &nbsp; User Name :{userDetailsData?.userName}
                        </small>
                      </h5>
                      <p className="mb-0">
                        <b>Mobile :</b> +91 {userDetailsData.contact_number}
                      </p>
                      <p className="mb-0">
                        <b>Email :</b> {userDetailsData?.email_id}
                      </p>
                      <p>
                        <b>Address :</b> {userDetailsData?.address}
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-12 text-right">
                      <h3>
                        {userDetailsData?.status === "true"
                          ? "Working"
                          : "Not Working"}
                      </h3>

                      <button
                        className="btn btn-outline-danger"
                        onClick={() =>
                          navigate(
                            `/users/edit/${userDetailsData?.users_serial_number}`
                          )
                        }
                      >
                        Edit Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <h1>Favorite Vendors</h1>
              {favVendorData && favVendorData.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  {favVendorData.map((item) => (
                    <div
                      style={{
                        flex: "0 1 30%",
                      }}
                      className="card"
                      key={item.vendor_id}
                      onClick={() => navigate(`/vendors/${item?.vendor_id}`)}
                    >
                      <div className="body text-center">
                        <div
                          className="chart easy-pie-chart-1"
                          data-percent={75}
                        >
                          <span>
                            {item && item.profile_photo ? (
                              <img
                                src={`https://brandapi.kreativemachinez.in/uploads/${item.profile_photo}`}
                                alt="user"
                                className="rounded-circle"
                              />
                            ) : (
                              <img
                                src="assets/images/sm/avatar1.jpg"
                                alt="user"
                                className="rounded-circle"
                              />
                            )}
                          </span>
                        </div>
                        <h6>
                          {item.first_name} {item.last_name}
                        </h6>
                        <ul className="social-links list-unstyled">
                          <li>
                            <a title="facebook" href="/">
                              <i className="zmdi zmdi-facebook" />
                            </a>
                          </li>
                          <li>
                            <a title="twitter" href="/">
                              <i className="zmdi zmdi-twitter" />
                            </a>
                          </li>
                          <li>
                            <a title="instagram" href="/">
                              <i className="zmdi zmdi-instagram" />
                            </a>
                          </li>
                        </ul>
                        <strong>{item.address}</strong>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <h5>No Favourite Vendor Found</h5>
              )}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export { UserDetails };
