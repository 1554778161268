/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { Layout, Loader, Uploaded } from "../components";
import { useParams } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import defaultImage from "../images/sm/avatar1.jpg";
import { useNavigate } from "react-router-dom";

const AssignmentDetails = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [vendorId, setVendorId] = useState(null);

  const [placeData, setPlaceData] = useState({
    latitude: null,
    longitude: null,
  });
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  console.log(token);
  console.log(vendorId);
  const getAssignmentDetail = useCallback(async () => {
    try {
      setLoading(true);
      const url = `${baseUrl}assignment/list/${id}`;
      const response = await axios.get(url);
      setAssignmentDetails(response.data.data);
      console.log(response.data.data);
      setVendorId(response.data?.data?.assigned_vendor_id);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching assignment details:", error);
      setLoading(false);
    }
  }, [id, baseUrl]);
  const imageUrl = "https://brandapi.kreativemachinez.in/uploads/";
  const getVendors = useCallback(async () => {
    try {
      const url = `${baseUrl}vendors/list`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVendorList(response.data.data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  }, [token, baseUrl]);

  const getVendorDetails = useCallback(async () => {
    if (vendorId !== null) {
      try {
        const url = `${baseUrl}vendors/list/${vendorId}`;
        console.log(url);
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
        setVendorDetails(response.data.data);
        return response.data;
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    } else {
      return null;
    }
  }, [vendorId, baseUrl, token]);

  useEffect(() => {
    getAssignmentDetail();
    getVendors();
  }, [getAssignmentDetail, getVendors]);

  useEffect(() => {
    if (vendorId !== null) {
      getVendorDetails();
    }
    if (assignmentDetails.place) {
      setPlaceData({
        latitude: assignmentDetails.place.place_latitude,
        longitude: assignmentDetails.place.place_longitude,
      });
    }
  }, [vendorId, getVendorDetails, assignmentDetails]);

  const handleVendorId = (e) => {
    setSelectedVendorId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = `${baseUrl}assign/assignVendor`;
      const data = {
        assignment_id: assignmentDetails?.assignment_id,
        vendor_id: selectedVendorId,
      };
      const response = await axios.post(url, data);
      getAssignmentDetail();
      console.log(response)
      return response.data;
    } catch (error) {
      console.error("Error submitting form:", error);
      throw error;
    }
  };

  console.log(assignmentDetails);
  useEffect(() => {
    if (placeData.latitude !== null && placeData.longitude !== null) {
      const iframeData = document.getElementById("iframeId");
      const lat = placeData.latitude;
      const lon = placeData.longitude;

      if (iframeData) {
        iframeData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;
      }
    }
  }, [placeData]);

  console.log(vendorDetails);

  return (
    <>
      <Layout>
        {loading && <Loader />}
        <div className="row clearfix">
          <div className="col-lg-8 col-md-12 left-box">
            <div className="card single_post">
              <div className="body">
                <div className="img-post">
                  {assignmentDetails?.banner_image?.length < 2 ? (
                    <div>
                      <img
                        className="d-block img-fluid"
                        src={`https://brandapi.kreativemachinez.in/uploads/${assignmentDetails?.banner_image[0]}`}
                        alt={`Slide`}
                      />
                    </div>
                  ) : (
                    <Slider {...settings}>
                      {assignmentDetails?.banner_image?.map((item, index) => (
                        <div key={index}>
                          <img
                            className="d-block img-fluid"
                            src={`https://brandapi.kreativemachinez.in/uploads/${item}`}
                            alt={`Slide ${index + 1}`}
                          />
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
                <h3>{assignmentDetails?.title}</h3>
                <p>{assignmentDetails?.remarks}</p>
              </div>
            </div>
            {placeData.latitude !== null && placeData.longitude !== null && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <iframe
                  id="iframeId"
                  height="500px"
                  width="60%"
                  style={{
                    border: "0",
                    marginRight: "20px",
                  }}
                ></iframe>
                <div>
                  <h5>
                    <strong>Location:</strong>{" "}
                    {assignmentDetails?.location?.location_name}
                  </h5>
                  <h5>
                    <strong>Area:</strong> {assignmentDetails?.area?.area_name}
                  </h5>
                  <h5>
                    <strong>Place:</strong>{" "}
                    {assignmentDetails?.place?.place_address}
                  </h5>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-4 col-md-12 right-box">
            <div className="card">
              <div className="body search">
                <div className="input-group mb-0">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-magnifier" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>
            <div className="card">
              <div className="header">
                <h2>
                  Email Newsletter{" "}
                  <small>
                    Get our products/news earlier than others, let’s get in
                    touch.
                  </small>
                </h2>
              </div>
              <div className="body widget newsletter">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="icon-paper-plane" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card right-box">
              <div className="header">
                <h2>All Banners</h2>
              </div>
              <div className="body widget">
                <ul
                  className="list-unstyled instagram-plugin mb-0"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {assignmentDetails?.banner_image?.map((item, index) => (
                    <li key={index}>
                      <img
                        src={`https://brandapi.kreativemachinez.in/uploads/${item}`}
                        alt="banner"
                        style={{
                          height: 100,
                          width: 150,
                          padding: "10px",
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {assignmentDetails?.assignment_status_id === null ? (
          <div className="mt-3">
            <form className="row clearfix" onSubmit={(e) => handleSubmit(e)}>
              <div className="col-lg-6 col-md-12">
                <div className="card" style={{ border: "none" }}>
                  <label style={{ fontSize: "18px", marginBottom: "10px" }}>
                    Vendor Selection
                  </label>
                  <div className="c_multiselect">
                    <select
                      id="single-selection"
                      name="vendor_id"
                      className="multiselect multiselect-custom"
                      style={{
                        width: "100%",
                        padding: "10px",
                        fontSize: "16px",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        color: "#555",
                        border: "1px solid #ddd",
                        cursor: "pointer",
                        outline: "none",
                      }}
                      onChange={handleVendorId}
                      value={selectedVendorId}
                    >
                      <option value="0">Select a Vendor</option>
                      {vendorList.map((vendor) => (
                        <option key={vendor.vendor_id} value={vendor.vendor_id}>
                          {vendor.first_name} {vendor.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button type="submit" className="btn btn-primary">
                  Assign To Vendor
                </button>
              </div>
            </form>
          </div>
        ) : (
          <>
            <div className="row clearfix row-deck mt-3">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="card"
                  onClick={() =>
                    navigate(`/vendors/${vendorDetails?.vendor_id}`)
                  }
                >
                  <div className="body text-center">
                    <div className="chart easy-pie-chart-1" data-percent={75}>
                      <span>
                        {vendorDetails && vendorDetails?.profile_photo ? (
                          <img
                            src={`${imageUrl}${vendorDetails?.profile_photo}`}
                            alt="user"
                            className="rounded-circle"
                          />
                        ) : (
                          <img
                            src={defaultImage}
                            alt="user"
                            className="rounded-circle"
                          />
                        )}
                      </span>
                    </div>
                    <h6>
                      {vendorDetails?.first_name}
                      {vendorDetails?.last_name}
                    </h6>
                    <small>
                      795 Folsom Ave, Suite 600 San Francisco,
                      <br /> CADGE 94107
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {assignmentDetails?.images &&
        assignmentDetails?.images.image_id !== null ? (
          <Uploaded assignmentId ={assignmentDetails?.assignment_id} />
          
        ) : null}
      </Layout>
    </>
  );
};

export { AssignmentDetails };
