import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ResetPssword = () => {
    const navigate = useNavigate();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;


  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      const url = `${baseUrl}commonlogin/reset-password`;
      const data = {
        userName: userName,
        password:password
      };
      const response = await axios.post(url, data);
      console.log(response)
      navigate("/");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
    <div id="wrapper" className="theme-cyan">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                {/* <img src="assets/images/logo-white.svg" alt="Iconic" /> */}
                <h4
                  className=""
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  BrandMate
                </h4>
              </div>
              <div className="card">
                <div className="header">
                  <p className="lead">Enter A Password According to You</p>
                </div>
                <div className="body">
                    <form
                      className="form-auth-small"
                      onSubmit={(e) => resetPassword(e)}
                    >
                      <div className="form-group">
                        <label
                          htmlFor="signin-userName"
                          className="control-label sr-only"
                        >
                          User Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="signin-userName"
                          value={userName}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder="User Name"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="signin-password"
                          className="control-label sr-only"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="signin-password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Reset Password
                      </button>
                    </form>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export { ResetPssword };