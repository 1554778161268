import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [assignmentList, setAssignmentList] = useState([]);
  const navigate = useNavigate();
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 5000,
    autoplaySpeed: 500,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAssignment = useCallback(async () => {
    try {
      const response = await axios.get(`${baseUrl}assignment/list`);
      setAssignmentList(response.data.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }, [baseUrl]);

  useEffect(() => {
    getAssignment();
  }, [getAssignment]);

  // Filter assignments where assignment_status_id is not null
  const filteredAssignments = assignmentList.filter(
    (item) => item.assignment_status_id !== null
  );

  // Dynamically adjust the number of slidesToShow and slidesToScroll
  let dynamicSettings = { ...settings };
  if (filteredAssignments.length < 3) {
    dynamicSettings.slidesToShow = filteredAssignments.length;
    dynamicSettings.slidesToScroll = filteredAssignments.length;
  }

  const plannedAssignments = assignmentList.filter(
    (item) => item.assignment_status_id === null
  );

  const assignedAssignments = filteredAssignments.filter(
    (item) => item.assignment_status_id === 1
  );
  const totalAssignments = assignmentList.length;
  const totalAssignedAssignments = assignedAssignments.length;
  const doneAssignments = filteredAssignments.filter(
    (item) => item.assignment_status_id === 2
  );
  const completedAssignments = assignmentList.filter(
    (item) => item.assignment_status_id === 3
  );

  const totalDoneAssignments = doneAssignments.length;

  return (
    <>
      <Layout>
        <div className="row clearfix row-deck">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card number-chart">
              <div className="body">
                <h4 className="mb-4 mt-2">
                  <span className="text-uppercase">
                    Total <br /> Assignments:
                  </span>{" "}
                  {totalAssignments}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card number-chart">
              <div className="body">
                <h4 className="mb-4 mt-2">
                  <span className="text-uppercase">
                    Assigned <br /> Assignments:
                  </span>{" "}
                  {totalAssignedAssignments}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card number-chart">
              <div className="body">
                <h4 className="mb-4 mt-2">
                  <span className="text-uppercase">
                    Assignments <br /> Done:
                  </span>{" "}
                  {totalDoneAssignments}
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card planned_task">
              <div className="header">
                <h2>Planned</h2>
              </div>
              <div className="body taskboard">
                <div className="dd" data-plugin="nestable">
                  <ol className="dd-list">
                    {plannedAssignments && plannedAssignments ? (
                      plannedAssignments.map((item) => (
                        <li
                          className="dd-item"
                          data-id={1}
                          key={item.assignment_id}
                        >
                          <div className="dd-content p-15">
                            <h5>{item.title}</h5>
                            <p>
                              {item.remarks.split(" ").slice(0, 30).join(" ")}
                            </p>

                            <hr />
                            <div className="action">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary"
                                title="Edit"
                                onClick={() =>
                                  navigate(
                                    `/all-assignment/${item.assignment_id}`
                                  )
                                }
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No Planned Assignments</p>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card progress_task">
              <div className="header">
                <h2>In progress</h2>
              </div>
              <div className="body taskboard">
                <div className="dd" data-plugin="nestable">
                  <ol className="dd-list">
                    {assignedAssignments && assignedAssignments ? (
                      assignedAssignments.map((item) => (
                        <li
                          className="dd-item"
                          data-id={1}
                          key={item.assignment_id}
                        >
                          <div className="dd-content p-15">
                            <h5>{item.title}</h5>
                            <p>
                              {item.remarks.split(" ").slice(0, 30).join(" ")}
                            </p>

                            <hr />
                            <div className="action">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary"
                                title="Edit"
                                onClick={() =>
                                  navigate(
                                    `/all-assignment/${item.assignment_id}`
                                  )
                                }
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No Assigned Assignments</p>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card completed_task">
              <div className="header">
                <h2>Completed</h2>
              </div>
              <div className="body taskboard">
                <div className="dd" data-plugin="nestable">
                  <ol className="dd-list">
                    {completedAssignments && completedAssignments ? (
                      completedAssignments.map((item) => (
                        <li
                          className="dd-item"
                          data-id={1}
                          key={item.assignment_id}
                        >
                          <div className="dd-content p-15">
                            <h5>{item.title}</h5>
                            <p>
                              {item.remarks.split(" ").slice(0, 30).join(" ")}
                            </p>

                            <hr />
                            <div className="action">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary"
                                title="Edit"
                                onClick={() =>
                                  navigate(
                                    `/all-assignment/${item.assignment_id}`
                                  )
                                }
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No Completed Assignments</p>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <CrouselComp assignmentList={filteredAssignments} heading={"Ongoing Assignments:"}/>
       <CrouselComp assignmentList={assignmentList} heading={"All Assignments:"}/> */}
      </Layout>
    </>
  );
};

export { Home };
