/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Layout, Loader } from "../components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AssignmentListing = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [assignmentData, setAssignmentData] = useState([]);

  const imageUrl = "https://brandapi.kreativemachinez.in/uploads/"

  const navigate = useNavigate();
  const getAssignments = useCallback(async () => {
    try {
      const url = `${baseUrl}assignment/list`;
      const response = await axios.get(url);
      setAssignmentData(response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }, [baseUrl]);
  useEffect(() => {
    getAssignments();
  }, [getAssignments]);
  console.log(assignmentData);

  return (
    <>
      <Layout>
        <div className="row clearfix">
          {assignmentData.length > 0 ? (
            assignmentData?.map((assignment) => (
              <div
                className="col-lg-4 col-md-12"
                key={assignment?.assignment_id}
              >
                <div
                  className="card w_social2 overflowhidden"
                  onClick={() =>
                    navigate(`/all-assignment/${assignment?.assignment_id}`)
                  }
                >
                  <div className="pw_img">
                    <img
                      className="img-fluid"
                      src={`${imageUrl}${assignment?.banner_image[0]}`}
                      alt="About the"
                    />
                  </div>
                  <div className="pw_content">
                    <div className="pw_header top_counter">
                      <div className="icon bg-transparent">
                        <img
                          src="assets/images/xs/avatar5.jpg"
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <div>{assignment?.title}</div>
                        <h6>About</h6>
                      </div>
                    </div>
                    <div className="pw_meta">
                      <div className="row">
                        <div className="col-4">
                          <h5>{assignment?.created_by}</h5>
                          <small>Created By</small>
                        </div>
                        <div className="col-4">
                          <h5>532</h5>
                          <small>Following</small>
                        </div>
                        <div className="col-4">
                          <h5>656</h5>
                          <small>Tweets</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            ) : <Loader/>}
        </div>
        
      </Layout>
    </>
  );
};

export { AssignmentListing };
