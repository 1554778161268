import React, { useState } from "react";
// import { GoogleMap, LoadScript } from "@react-google-maps/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LeafletMap from "./LeaftletMap";
import Toast from "../utils/AlertToast";

const MapComponent = ({ placeName, areaId }) => {

  const [currentPos, setCurrentPos] = useState(null);
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  console.log(currentPos)

  const handleSubmit = async () => {
    try {
      const url = `${baseUrl}place/create`;
      const data = {
        place_address: placeName,
        area_id: Number(areaId),
        place_longitude: currentPos?.lng,
        place_latitude: currentPos?.lat,
        places_status: 0,
      };
      console.log(data);
      const response = await axios.post(url, data);
      navigate("/create-area");
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  console.log(placeName, areaId);
  const center = [22.5726, 88.3639];
  const zoom = 13;

  // const apikey = "AIzaSyC76U5JAVuO4Ok9Pz2nsOrN-zI6EJpG7CE";
  return (
    <>
      <div className="body">
        {/* <LoadScript googleMapsApiKey={apikey}>
          {document && document.readyState === "complete" ? (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={15}
              center={center}
              onClick={handleMapClick}
            ></GoogleMap>
          ) : (
            <div>loading...</div>
          )}
        </LoadScript> */}
        <LeafletMap center={center} zoom={zoom} setCurrentPos={setCurrentPos} />
        <button
          type="button"
          className="btn btn-primary mt-5"
          onClick={handleSubmit}
        >
          Create
        </button>
      </div>
    </>
  );
};

export default MapComponent;
