import React, { useEffect, useState } from "react";
import axios from "axios";
import { Layout } from "../components";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [profileData, setProfileData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const getUserData = async () => {
      const user_id = localStorage.getItem("user_id");
      const vendor_id = localStorage.getItem("vendor_id");

      if (user_id) {
        try {
          const response = await axios.get(`${baseUrl}users/list/${user_id}`);
          console.log(response.data);
          setProfileData(response.data.data);
          return response.data;
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
      if (vendor_id) {
        try {
          const response = await axios.get(
            `${baseUrl}vendors/list/${vendor_id}`
          );
          console.log(response.data);
          setProfileData(response.data);
          return response.data;
        } catch (error) {
          console.error("Error fetching vendor profile:", error);
        }
      }
    };

    getUserData();
  }, [baseUrl]);
  console.log(profileData);
  const imageUrl = "https://brandapi.kreativemachinez.in/uploads/";
  return (
    <>
      <Layout>
        <div className="row clearfix">
          <div
            className="col-lg-6 col-md-12"
            style={{
              display: "contents",
            }}
          >
            <div className="card">
              <div className="body">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12">
                    <div className="receipt-left">
                      <img
                        className="img-fluid"
                        src={`${imageUrl}${profileData?.profile_photo}`}
                        style={{ width: 71, height: 71, borderRadius: 43 }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 text-right">
                    <h5>
                      {profileData.user_type_id === 1
                        ? "Admin"
                        : profileData.user_type_id === 2
                        ? "User"
                        : profileData.user_type_id === 3
                        ? "Vendor"
                        : null}
                    </h5>

                    <p className="mb-0">
                      +91 {profileData.contact_number}{" "}
                      <i className="fa fa-phone" />
                    </p>
                    <p className="mb-0">
                      {profileData?.email_id}{" "}
                      <i className="fa fa-envelope-o" />
                    </p>
                    <p>
                      Indian {" "}<i className="fa fa-location-arrow" />
                    </p>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-8 col-md-12">
                    <h5>
                      {profileData?.first_name}
                      {profileData?.last_name}
                      <small>
                        &nbsp; | &nbsp; User Name :{profileData?.userName}
                      </small>
                    </h5>
                    <p className="mb-0">
                      <b>Mobile :</b> +91 {profileData.contact_number}
                    </p>
                    <p className="mb-0">
                      <b>Email :</b> {profileData?.email_id}
                    </p>
                    <p>
                      <b>Address :</b> {profileData?.address}
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-12 text-right">
                    <h3>
                      {profileData?.status === "true"
                        ? "Working"
                        : "Not Working"}
                    </h3>

                    <button
                      className="btn btn-outline-danger"
                      onClick={() =>
                        navigate(
                          `/users/edit/${profileData?.users_serial_number}`
                        )
                      }
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export { MyProfile };
