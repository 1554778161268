import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { Loader } from "./Loader"; // Import your Loader component

const CrouselComp = (props) => {
  const { assignmentList, heading } = props;
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 4,
    speed: 5000,
    autoplaySpeed: 500,
    cssEase: "linear",
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageUrl = "https://brandapi.kreativemachinez.in/uploads/";

  useEffect(() => {
    // Simulate data and image loading delay
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed

    // Cleanup the timeout on component unmount
    return () => clearTimeout(loadingTimeout);
  }, []);

  let dynamicSettings = { ...settings };

  return (
    <div style={{ marginTop: "10px" }}>
      <div>
        <h3
          style={{
            color: "var(--primary-color)",
            marginBottom: "10px",
          }}
        >
          {heading}
        </h3>
      </div>
      <div>
        {isLoading ? (
          <Loader />
        ) : assignmentList.length >= 3 ? (
          <Slider {...dynamicSettings}>
            {assignmentList.map((item, index) => (
              <div
                key={index}
                onClick={() =>
                  navigate(`/all-assignment/${item?.assignment_id}`)
                }
              >
                <img
                  src={`${imageUrl}${item?.banner_image[0]}`}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "200px",
                    overflow: "hidden",
                    paddingRight: "20px",
                  }}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {assignmentList.map((item, index) => (
              <div
                key={index}
                onClick={() =>
                  navigate(`/all-assignment/${item?.assignment_id}`)
                }
              >
                <img
                  src={`${imageUrl}${item?.banner_image[0]}`}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "350px",
                    overflow: "hidden",
                    paddingRight: "20px",
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { CrouselComp };
