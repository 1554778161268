import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FavVendors = () => {
  const [favVendorData, setFavVendorData] = useState([]);
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userId = localStorage.getItem("user_id");

  const getFavVendor = useCallback(async () => {
    try {
      const response = await axios.get(`${baseUrl}favorites/${userId}`);
      setFavVendorData(response.data.favoriteVendors);
      return response.data;
    } catch (error) {
      throw error;
    }
  }, [baseUrl, userId]);

  useEffect(() => {
    getFavVendor();
  }, [getFavVendor]);

  return (
    <>
      <Layout>
        <div className="row clearfix row-deck">
          {favVendorData &&
            favVendorData.map((item) => (
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="card"
                  key={item.vendor_id}
                  onClick={() => navigate(`/vendors/${item?.vendor_id}`)}
                >
                  <div className="body text-center">
                    <div className="chart easy-pie-chart-1" data-percent={75}>
                      <span>
                        {item && item.profile_photo ? (
                          <img
                            src={`https://brandapi.kreativemachinez.in/uploads/${item.profile_photo}`}
                            alt="user"
                            className="rounded-circle"
                          />
                        ) : (
                          <img
                            src="assets/images/sm/avatar1.jpg"
                            alt="user"
                            className="rounded-circle"
                          />
                        )}
                      </span>
                    </div>
                    <h6>
                      {item.first_name} {item.last_name}
                    </h6>
                    <ul className="social-links list-unstyled">
                      <li>
                        <a title="facebook" href="/">
                          <i className="zmdi zmdi-facebook" />
                        </a>
                      </li>
                      <li>
                        <a title="twitter" href="/">
                          <i className="zmdi zmdi-twitter" />
                        </a>
                      </li>
                      <li>
                        <a title="instagram" href="/">
                          <i className="zmdi zmdi-instagram" />
                        </a>
                      </li>
                    </ul>

                    <strong>{item.address}</strong>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Layout>
    </>
  );
};

export { FavVendors };
