import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

const Uploaded = (props) => {
  const { assignmentId } = props;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [reviews, setReviews] = useState({});
  const [accepted, setAccepted] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [assignmentDetails, setAssignmentDetails] = useState({});

  const getAssignmentDetail = useCallback(async () => {
    try {
      const url = `${baseUrl}assignment/list/${assignmentId}`;
      const response = await axios.get(url);
      setAssignmentDetails(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching assignment details:", error);
    }
  }, [assignmentId, baseUrl]);

  const acceptClick = async ({ imageId, assignmentId }) => {
    try {
      const url = `${baseUrl}assignment/review-images/${assignmentId}`;
     console.log( url)
      const data = {
        images: [
          {
            imageId: imageId,
            imageStatus: "2",
            textReview: reviews[imageId] || "",
          }
        ]
      };
      console.log(data);
  
      const response = await axios.patch(url, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log(response.data);
      getAssignmentDetail();
      setAccepted(true);
    } catch (error) {
      console.log(error);
    }
  };
  

  const rejectClick = async ({ imageId, assignmentId }) => {

    try {
      const url = `${baseUrl}assignment/review-images/${assignmentId}`;
      const data = {
        images: [
          {
            imageId: imageId,
            imageStatus: "3",
            textReview: reviews[imageId] || "",
          }
        ]
      };
      const response = await axios.patch(url, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      getAssignmentDetail();
      setRejected(true);
    } catch (error) {
      console.log(error)
    }
    
  };

  useEffect(() => {
    getAssignmentDetail();
  }, [getAssignmentDetail]);

  // console.log(assignmentDetails);

  return (
    <div className="card right-box">
      <div className="header">
        <h2>Uploaded Images Banners</h2>
      </div>
      <div className="body widget">
        <div className="list-unstyled instagram-plugin mb-0">
          {assignmentDetails?.images?.map((item, index) => (
            <div
              key={item.image_id}
              style={{
                display: "flex",
                overflow: "hidden",
                alignItems: "center",
              }}
            >
              <img
                src={`https://brandapi.kreativemachinez.in/assignment-uploads/${assignmentDetails?.assignment_id}/${item?.image_url}`}
                alt="banner"
                style={{
                  padding: "10px",
                  objectFit: "cover",
                  height: "300px",
                  width: "300px",
                  borderRadius: "20px",
                }}
              />
              <div
                style={{
                  width: "100%",
                }}
              >
                {item.image_status === 1 && (
                  <div>
                    <h5>Enter Your Review</h5>
                    <textarea
                      value={reviews[item.image_id] || ""}
                      style={{
                        height: "200px",
                        width: "100%",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                      onChange={(e) =>
                        setReviews({
                          ...reviews,
                          [item.image_id]: e.target.value,
                        })
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-outline-primary mr-2"
                      onClick={() =>
                        acceptClick({
                          imageId: item.image_id,
                          assignmentId: assignmentDetails?.assignment_id,
                        })
                      }
                    >
                      Accept
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => rejectClick({
                          imageId: item.image_id,
                          assignmentId: assignmentDetails?.assignment_id,
                        })}
                    >
                      Reject
                    </button>
                  </div>
                )}
                {item.image_status === 2 && (
                  <div>
                    <h5 style={{
                      backgroundColor: "#a4cd89",
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "5px"
                    }}>Accepted</h5>
                    <p>Review:{" "}<span style={{
                      fontWeight: "bold"
                    }}>{item.text_review}</span></p>
                  </div>
                )}
                {item.image_status === 3 && (
                  <div>
                    <h5 style={{
                      backgroundColor: "#FF7B7B",
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "5px"
                    }}>Rejected</h5>
                    
                    <p>Review:{" "}<span style={{
                      fontWeight: "bold"
                    }}>{item.text_review}</span></p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Uploaded };
