import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import placeholdericon from "../images/placeholder(1).png";
import { useState } from "react";
import { useMapEvents } from "react-leaflet";
import PropTypes from "prop-types";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

function LeafletMap({ center, zoom, setCurrentPos }) {
    const [currentPos, setCurrentPosState] = useState(null);
    function ClickHandler({ setCurrentPos }) {
      useMapEvents({
          click(e) {
            setCurrentPos(e.latlng);
          }
      });
      return null;
  }
    const customIcon = new Icon({
        iconUrl: placeholdericon,
        iconSize: [38, 38]
    });

    if(currentPos !== null) {
      setCurrentPos(currentPos)
    }

    return (
        <MapContainer center={center} zoom={zoom} className="map">
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ClickHandler setCurrentPos={setCurrentPosState} />
            {currentPos && (
                <Marker position={currentPos} draggable={true} icon={customIcon}>
                    <Popup position={currentPos}>
                        Current location: <pre>{JSON.stringify(currentPos, null, 2)}</pre>
                    </Popup>
                </Marker>
            )}
        </MapContainer>
    );
}

LeafletMap.propTypes = {
    center: PropTypes.array.isRequired,
    zoom: PropTypes.number.isRequired,
    setCurrentPos: PropTypes.func.isRequired,
};

export default LeafletMap;
