import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Toast from "../utils/AlertToast";
const Login = () => {
  const navigate = useNavigate();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(10);
  const [errorCode, setErrorCode] = useState(0);

  const [userData, setUserData] = useState({});

  const [otp, setOtp] = useState("");

  const baseUrl = process.env.REACT_APP_BASE_URL;
  // const baseUrl = "http://localhost:3001/api/";
  // console.log(baseUrl);

  const resetFields = () => {
    setUsername("");
    setPassword("");
  }

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    try {
      if (!userName || !password) {
        alert("Please input User Name and Password");
        return;
      }
      const url = `${baseUrl}commonlogin/login`;
      // console.log(url);
      const data = {
        userName: userName,
        password: password,
      };

      const response = await axios.post(url, data);
      // console.log(response);
      setUserData(response.data.data);
      if(response?.data?.status === 200){
        Toast.fire({
          icon: "success",
          title: "Your OTP has been sent successfully!",
        });
      }
      setOtpShow(true);
      resetFields();
      return response.data;
    } catch (error) {
      setPassword("");
      setUsername("");
      Toast.fire({
        icon: "error",
        title: "Error occurred. Please try again.",
      });
      console.log(error);
    }
  };

  const handleOtpLogin = async (e) => {
    e.preventDefault();
    try {
      const url = `${baseUrl}commonlogin/otp-validation`;
      let data = {};

      if (userData.vendor) {
        data = {
          id: userData.vendor.vendor_id,
          otp: otp,
        };
        const response = await axios.post(url, data);
        const { token } = response.data.data;

        const { vendor_id, user_type_id } = response.data.data.vendor;
        localStorage.setItem("vendor_id", vendor_id);
        localStorage.setItem(
          "type",
          user_type_id === 1 ? "Admin" : user_type_id === 2 ? "user" : "vendor"
        );
        Toast.fire({
          icon: "success",
          title: "Your OTP has been verified successfully!",
        });
        resetFields();
        localStorage.setItem("token", token);
        navigate("/home");

        return response.data;
      } else if (userData.user) {
        data = {
          id: userData.user.user_id,
          otp: otp,
        };
        const response = await axios.post(url, data);
        const { token } = response.data.data;

        if (response.data.data.user) {
          const { user_id, user_type_id } = response.data.data.user;
          localStorage.setItem("user_id", user_id);
          localStorage.setItem(
            "type",
            user_type_id === 1
              ? "Admin"
              : user_type_id === 2
              ? "user"
              : "vendor"
          );
        resetFields();
          Toast.fire({
            icon: "success",
            title: "Your OTP has been verified successfully!",
          });
          localStorage.setItem("token", token);
          navigate("/home");

          return response.data;
        }
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.status,
      });
      setErrorCode(error.response.data.status);
      // console.log(error);
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      const url = `${baseUrl}commonlogin/reset-password`;
      const data = {
        userName: userName,
        password: password,
      };
      const response = await axios.post(url, data);
      setOpen(false);
      resetFields();
      Toast.fire({
        icon: "success",
        title: "Your password has been reseted!",
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    let interval;

    if (otpShow) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [otpShow, timer, navigate]);

  const handleReset = () => {
    setOpen(!open);
    resetFields();
  };
  return (
    <>
      <div id="wrapper" className="theme-cyan">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                {/* <img src="assets/images/logo-white.svg" alt="Iconic" /> */}
                <h4
                  className=""
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  BrandMate
                </h4>
              </div>
              {otpShow === false ? (
                <div className="card">
                  <div className="header">
                    <p className="lead">Login to your account</p>
                  </div>
                  <div className="body">
                    {open === false ? (
                      <form
                        className="form-auth-small"
                        onSubmit={(e) => handleSubmitLogin(e)}
                      >
                        <div className="form-group">
                          <label
                            htmlFor="signin-userName"
                            className="control-label sr-only"
                          >
                            User Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="signin-userName"
                            value={userName}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="User Name"
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="signin-password"
                            className="control-label sr-only"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="signin-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                          />
                        </div>
                        <div className="form-group clearfix">
                          <label className="fancy-checkbox element-left">
                            <input type="checkbox" />
                            <span>Remember me</span>
                          </label>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                        >
                          Send Otp
                        </button>
                        <div className="bottom">
                          <span className="helper-text m-b-10">
                            <i className="fa fa-lock" />
                            <span onClick={handleReset}>Reset password?</span>
                          </span>
                          {/* <span>
                        Don't have an account?{" "}
                        <a href="page-register.html">Register</a>
                      </span> */}
                        </div>
                      </form>
                    ) : (
                      <form
                        className="form-auth-small"
                        onSubmit={(e) => resetPassword(e)}
                      >
                        <div className="form-group">
                          <label
                            htmlFor="signin-userName"
                            className="control-label sr-only"
                          >
                            User Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="signin-userName"
                            value={userName}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="User Name"
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="signin-password"
                            className="control-label sr-only"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="signin-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                        >
                          Reset Password
                        </button>
                        <div className="bottom" style={{ cursor: "pointer" }}>
                          <span>
                            Don't have an account?
                            <span onClick={handleReset}>Back to LOGIN</span>
                          </span>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              ) : (
                <div className="card">
                  <div className="body">
                    <div className="user text-center m-b-30">
                      <img
                        src="assets/images/user-small.png"
                        className="rounded-circle"
                        alt="Avatar"
                      />
                      {userData?.user && userData.user ? (
                        <h4 className="name m-t-10">{`${userData.user.first_name} ${userData.user.last_name}`}</h4>
                      ) : (
                        <h4 className="name m-t-10">{`${userData.vendor.first_name} ${userData.vendor.last_name}`}</h4>
                      )}

                      <p>{`Your Otp is : ${userData.otp}`}</p>
                    </div>
                    <div className="timer">
                      <p>{`Time remaining: ${timer} seconds`}</p>
                    </div>
                    <form onSubmit={(e) => handleOtpLogin(e)}>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter your Otp ..."
                          value={otp}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            const truncatedValue = sanitizedValue.slice(0, 4);
                            setOtp(truncatedValue);
                          }}
                          maxLength="4"
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Login
                      </button>
                      {errorCode && errorCode === 401 ? (
                        <p className="text-danger mt-2">Invalid Otp</p>
                      ) : null}
                      <div
                        className="bottom text-center mt-3"
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <span onClick={() => setOtpShow(false)}>
                            Back to LOGIN
                          </span>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Login };
