import React from 'react'
import { Layout } from '../components'

const CreateMaster = () => {
  return (
    <>
    <Layout>
        
    </Layout>
    
    </>
  )
}

export  { CreateMaster }