import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "../components";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Loader } from "../components";
import { useNavigate } from "react-router-dom";

const VendorDetails = () => {
  const { id } = useParams();
  const [VendorDeatils, setVendorDetails] = useState([]);
  const [isLoading, setIsLoding] = useState(false);
  const [assignedAssignment, setAssignedAssignment] = useState([]);
  const userId = localStorage.getItem("user_id");

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const getVendorDetails = useCallback(async () => {
    try {
      setIsLoding(true);
      const url = `${baseUrl}vendors/list/${id}`;
      const token = localStorage.getItem("token");
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVendorDetails(response.data.data);
      setIsLoding(false);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }, [id, baseUrl]);

  const getAssignmentAcordingToVendor = useCallback(async () => {
    try {
      const url = `${baseUrl}assign/assignVendor/list/${id}`;
      const response = await axios.get(url);
      // console.log(response.data.data);
      setAssignedAssignment(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [baseUrl, id]);

  const makeFav = async () => {
    try {
      const url = `${baseUrl}favorites`;

      const data = {
        userId: userId,
        vendorId: id,
      };
      const response = await axios.post(url, data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendorDetails();
    getAssignmentAcordingToVendor();
  }, [getVendorDetails, getAssignmentAcordingToVendor]);
  const imageUrl = "https://brandapi.kreativemachinez.in/uploads/";
  // console.log(assignedAssignment);
  console.log(VendorDeatils)
  return (
    <>
      <Layout>
        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="row clearfix">
            <div
              className="col-lg-6 col-md-12"
              style={{
                display: "contents",
              }}
            >
              <div className="card">
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-12">
                      <div className="receipt-left">
                        <img
                          className="img-fluid"
                          src={`${imageUrl}${VendorDeatils?.profile_photo}`}
                          style={{ width: 71, height: 71, borderRadius: 43 }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 text-right">
                      <h5>Vendor</h5>
                      <p className="mb-0">
                        +91 {VendorDeatils.contact_number}{" "}
                        <i className="fa fa-phone" />
                      </p>
                      <p className="mb-0">
                        {VendorDeatils?.email_id}{" "}
                        <i className="fa fa-envelope-o" />
                      </p>
                      <p>
                        Indian <i className="fa fa-location-arrow" />
                      </p>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-8 col-md-12">
                      <h5>
                        {VendorDeatils?.first_name}
                        {VendorDeatils?.last_name}
                        <small>
                          &nbsp; | &nbsp; User Name : {VendorDeatils?.userName}
                        </small>
                      </h5>
                      <p className="mb-0">
                        <b>Mobile :</b> +91 {VendorDeatils.contact_number}
                      </p>
                      <p className="mb-0">
                        <b>Email :</b> {VendorDeatils?.email_id}
                      </p>
                      <p>
                        <b>Address :</b> {VendorDeatils?.address}
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-12 text-right">
                      <h3>
                        {VendorDeatils?.status === "true"
                          ? "Working"
                          : "Not Working"}
                      </h3>

                      <button className="btn btn-outline-danger mr-2" onClick={() => navigate(`/vendors/edit/${VendorDeatils?.vendor_id}`)}>
                        Edit Profile
                      </button>
                      <button className="btn btn-primary" onClick={makeFav}>
                        Make Favorite
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <h5>Assigned Assignments</h5>
        </div>
        {isLoading === true ? (
          <Loader />
        ) : assignedAssignment && assignedAssignment.length > 0 ? (
          <div className="row clearfix">
            {assignedAssignment.map((item) => (
              <div
                className="col-lg-3 col-md-6 col-sm-12"
                key={item.assignment_id}
              >
                <div
                  className="card"
                  onClick={() =>
                    navigate(`/all-assignment/${item.assignment_id}`)
                  }
                >
                  <div className="file">
                    <div className="image">
                      <img
                        src={`https://brandapi.kreativemachinez.in/uploads/${item.banner_image[0]}`}
                        alt={`Assignment ${item.assignment_id}`}
                        className="img-fluid"
                      />
                    </div>
                    <div className="file-name p-3">
                      <h5 className="m-b-5">{item.title}</h5>
                      <small>
                        Date:
                        <span className="date text-muted">Dec 11, 2017</span>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <h5>No Assignment is Assigned</h5>
        )}
      </Layout>
    </>
  );
};

export { VendorDetails };
